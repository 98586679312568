import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const firebaseAddRequest = (contact: ContactItem) => {
  firebase
    .firestore()
    .collection('requests')
    .add({ contact })
    .catch((error: firebase.FirebaseError) => {
      console.log(error.message);
      return;
    });
};
