import { css } from '@emotion/react';
import { breakpoints, colors, fontFamily } from 'style';

const root = css`
  grid-column: 2 / span 4;
  margin: 0 0 calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    margin: 0 0 calc(var(--unit) * 12);
  }
`;

const title = css`
  font-family: ${fontFamily.primary};
  font-size: var(--font-size-xxLarge);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xxxLarge);

  margin-bottom: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-huge);
    line-height: var(--line-height-huge);
    margin-bottom: var(--spacing-xxxlrg);
  }
`;

const breadcrumbs = css`
  display: flex;
  align-items: center;

  font-family: ${fontFamily.secondary};
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);

  color: ${colors.primary};
`;

const breadcrumb = css`
  position: relative;
  color: ${colors.primary};
  transition: width 0.6s ease-out;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${colors.primary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${colors.secondary};
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

const currentBreadcrumb = css`
  color: ${colors.textLight};
`;

const svg = css`
  margin: 0 var(--spacing-med);
`;

export const contadctHeaderStyles = {
  title,
  root,
  breadcrumbs,
  breadcrumb,
  currentBreadcrumb,
  svg,
};
