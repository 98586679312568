interface contatctDetails {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  message: string;
  isGDPRChecked: boolean;
}

export const formCheck = ({
  name,
  surname,
  email,
  phoneNumber,
  message,
  isGDPRChecked,
}: contatctDetails) => {
  if (
    !/^[a-zA-Z\s]*$/.test(name) ||
    !name ||
    !/^[a-zA-Z\s]*$/.test(surname) ||
    !surname ||
    !/\S+@\S+\.\S+/.test(email) ||
    !email ||
    !/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(phoneNumber) ||
    !phoneNumber ||
    !message ||
    !isGDPRChecked
  )
    return true;
  else return false;
};
