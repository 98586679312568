import { Link } from 'gatsby';
import React from 'react';
import { contadctHeaderStyles } from './ContactHeader.styles';
import ArrowRightSvg from 'assets/icons/arrowRight.svg';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const ContactHeader: React.FC = () => {
  return (
    <div css={contadctHeaderStyles.root}>
      <InView threshold={0.4} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.h1
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
            transition={{ ease: 'circOut', duration: 0.4 }}
            css={contadctHeaderStyles.title}
          >
            <FormattedMessage id="contact.header.title" />
          </motion.h1>
        )}
      </InView>
      <InView threshold={0.4} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
            transition={{ ease: 'circOut', duration: 0.4, delay: 0.3 }}
            css={contadctHeaderStyles.breadcrumbs}
          >
            <Link to="/" css={contadctHeaderStyles.breadcrumb}>
              <p>
                <FormattedMessage id="contact.header.breadcrumb" />
              </p>
            </Link>
            <ArrowRightSvg css={contadctHeaderStyles.svg} />
            <p css={contadctHeaderStyles.currentBreadcrumb}>
              <FormattedMessage id="contact.header.title" />
            </p>
          </motion.div>
        )}
      </InView>
    </div>
  );
};
