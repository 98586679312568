import { Grid, SeoTags } from 'components';
import { getSrc } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useImages } from 'hooks';
import React from 'react';
import { ContactForm } from '../contact-form';
import { ContactInfo } from '../contact-info';
import { ContactHeader } from '../header';
import { contactPageStyles } from './ContactPage.styles';

export const ContactPage: React.FC = () => {
  const { contactMeta } = useImages();
  const { formatMessage } = useIntl();

  return (
    <>
      <SeoTags
        title={formatMessage({ id: 'contact.seo.title' })}
        description={formatMessage({ id: 'contact.seo.description' })}
        imagePath={getSrc(contactMeta)}
      />
      <Grid css={contactPageStyles.root} type="page">
        <ContactHeader />
        <ContactForm />
        <ContactInfo />
      </Grid>
    </>
  );
};
