import { Input, Textarea } from 'components';
import { Checkbox } from 'components/checkbox';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { firebaseAddRequest, formCheck } from 'modules/contact';
import React, { useState } from 'react';
import { contactFormStyles } from './ContactForm.styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const ContactForm: React.FC = () => {
  const initialContactForm = {
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    message: '',
    isGDPRChecked: false,
  };
  const [contact, setContact] = useState(initialContactForm);
  const [hasNameErr, setHasNameErr] = useState(false);
  const [hasSurnameErr, setHasSurnameErr] = useState(false);
  const [hasEmailErr, setHasEmailErr] = useState(false);
  const [hasPhoneNumberErr, setHasPhoneNumberErr] = useState(false);
  const [hasMessageErr, setHasMessageErr] = useState(false);
  const [hasGDPRCheckedErr, setHasGDPRChechedErr] = useState(false);
  const { name, surname, email, phoneNumber, message, isGDPRChecked } = contact;
  const { formatMessage } = useIntl();

  const notify = () => {
    toast.success(formatMessage({ id: 'message.success' }), {
      position: 'bottom-left',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setHasNameErr(false);
    setHasSurnameErr(false);
    setHasEmailErr(false);
    setHasPhoneNumberErr(false);
    setHasMessageErr(false);
    setHasGDPRChechedErr(false);

    if (!/^[a-zA-Z\s]*$/.test(name) || !name) setHasNameErr(true);
    if (!/^[a-zA-Z\s]*$/.test(surname) || !surname) setHasSurnameErr(true);
    if (!/\S+@\S+\.\S+/.test(email) || !email) setHasEmailErr(true);
    if (
      !/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(phoneNumber) ||
      !phoneNumber
    )
      setHasPhoneNumberErr(true);
    message ? setHasMessageErr(false) : setHasMessageErr(true);
    isGDPRChecked ? setHasGDPRChechedErr(false) : setHasGDPRChechedErr(true);

    if (formCheck(contact)) return;

    firebaseAddRequest(contact);
    setContact(initialContactForm);
    notify();
  };

  return (
    <div css={contactFormStyles.root}>
      <InView threshold={0.4} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.h2
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
            transition={{ ease: 'easeInOut', duration: 0.6 }}
            css={contactFormStyles.subtitle}
          >
            <FormattedMessage id="contact.form.title" />
          </motion.h2>
        )}
      </InView>
      <form onSubmit={handleSubmit}>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.1 }}
              css={contactFormStyles.fieldWrapper}
              onClick={() => setHasNameErr(false)}
            >
              <Input
                hasErr={hasNameErr}
                labelName={formatMessage({ id: 'form.name.label' })}
                name="name"
                value={name}
                onChange={handleChange}
                placeholder={formatMessage({ id: 'form.name.placeholder' })}
                type="text"
              />
              <p
                css={
                  hasNameErr
                    ? contactFormStyles.errorMessage
                    : contactFormStyles.errorMessageHidden
                }
              >
                <FormattedMessage id="form.name.error" />
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.2 }}
              css={contactFormStyles.fieldWrapper}
              onClick={() => setHasSurnameErr(false)}
            >
              <Input
                hasErr={hasSurnameErr}
                labelName={formatMessage({ id: 'form.surname.label' })}
                name="surname"
                value={surname}
                onChange={handleChange}
                placeholder={formatMessage({ id: 'form.surname.placeholder' })}
                type="text"
              />
              <p
                css={
                  hasSurnameErr
                    ? contactFormStyles.errorMessage
                    : contactFormStyles.errorMessageHidden
                }
              >
                <FormattedMessage id="form.surname.error" />
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.3 }}
              css={contactFormStyles.fieldWrapper}
              onClick={() => setHasEmailErr(false)}
            >
              <Input
                hasErr={hasEmailErr}
                labelName={formatMessage({ id: 'form.email.label' })}
                name="email"
                value={email}
                onChange={handleChange}
                placeholder={formatMessage({ id: 'form.email.placeholder' })}
                type="email"
              />
              <p
                css={
                  hasEmailErr
                    ? contactFormStyles.errorMessage
                    : contactFormStyles.errorMessageHidden
                }
              >
                <FormattedMessage id="form.email.error" />
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.3 }}
              css={contactFormStyles.fieldWrapper}
              onClick={() => setHasPhoneNumberErr(false)}
            >
              <Input
                hasErr={hasPhoneNumberErr}
                labelName={formatMessage({ id: 'form.phone-number.label' })}
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleChange}
                placeholder={formatMessage({
                  id: 'form.phone-number.placeholder',
                })}
                type="tel"
              />
              <p
                css={
                  hasPhoneNumberErr
                    ? contactFormStyles.errorMessage
                    : contactFormStyles.errorMessageHidden
                }
              >
                <FormattedMessage id="form.phone-number.error" />
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.3 }}
              css={contactFormStyles.fieldWrapper}
              onClick={() => setHasMessageErr(false)}
            >
              <Textarea
                hasErr={hasMessageErr}
                labelName={formatMessage({ id: 'form.message.label' })}
                name="message"
                value={message}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    message: e.currentTarget.value,
                  })
                }
                placeholder={formatMessage({ id: 'form.message.placeholder' })}
              />
              <p
                css={
                  hasMessageErr
                    ? contactFormStyles.errorMessage
                    : contactFormStyles.errorMessageHidden
                }
              >
                <FormattedMessage id="form.message.error" />
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.3 }}
              css={contactFormStyles.fieldWrapper}
            >
              <Checkbox
                name="GDPR"
                checked={isGDPRChecked}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    isGDPRChecked: e.currentTarget.checked,
                  })
                }
                paragraph={formatMessage({ id: 'form.gdpr.message' })}
              />
              <p
                css={
                  hasGDPRCheckedErr
                    ? contactFormStyles.checkboxMessage
                    : contactFormStyles.errorMessageHidden
                }
              >
                <FormattedMessage id="form.gdpr.error" />
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.button
              ref={ref}
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.3 }}
              css={contactFormStyles.submitButton}
              type="submit"
            >
              <span css={contactFormStyles.buttonText}>
                <FormattedMessage id="button.submit" />
              </span>
            </motion.button>
          )}
        </InView>
      </form>
      <div css={contactFormStyles.toastContainer}>
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};
