import { Layout } from 'components/layout';
import React from 'react';
import { ContactPage } from 'modules/contact';

const Contact: React.FC = () => (
  <Layout isGhostNav={false}>
    <ContactPage />
  </Layout>
);
export default Contact;
