import { css } from '@emotion/react';
import { breakpoints, colors, fontFamily } from 'style';

const root = css`
  grid-column: 7 / span 5;
`;

const subtitle = css`
  font-family: ${fontFamily.primary};
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  margin-bottom: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xLarge);
    line-height: var(--line-height-xxLarge);
    margin-bottom: calc(var(--unit) * 14);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxxLarge);
  }
`;

const adressWrapper = css`
  margin-bottom: var(--spacing-lrg);
`;

const adress = css`
  font-family: ${fontFamily.secondary};
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-base);
`;

const emailAdressWrapper = css`
  font-family: ${fontFamily.secondary};
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-base);
  color: ${colors.textLight};

  margin-bottom: var(--unit);
`;

const emailAdress = css`
  position: relative;
  color: ${colors.text};

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${colors.secondary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${colors.secondary};
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

const phoneNumberWrapper = css`
  font-family: ${fontFamily.secondary};
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-base);
  color: ${colors.textLight};

  margin-bottom: var(--spacing-huge);
`;

const phoneNumber = css`
  position: relative;
  color: ${colors.text};

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${colors.secondary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${colors.secondary};
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

export const contactInfoStyles = {
  root,
  subtitle,
  adress,
  adressWrapper,
  emailAdressWrapper,
  emailAdress,
  phoneNumberWrapper,
  phoneNumber,
};
