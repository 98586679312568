import { motion } from 'framer-motion';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';
import InView from 'react-intersection-observer';
import { contactInfoStyles } from './ContactInfo.styles';

export const ContactInfo: React.FC = () => {
  return (
    <div css={contactInfoStyles.root}>
      <InView threshold={0.4} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.h2
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.3 }}
            css={contactInfoStyles.subtitle}
          >
            <FormattedMessage id="contact.info.title" />
          </motion.h2>
        )}
      </InView>
      <InView threshold={0.4} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.6 }}
            css={contactInfoStyles.adressWrapper}
          >
            <p css={contactInfoStyles.adress}>Udruga za kreatvni razvoj Slap</p>
            <p css={contactInfoStyles.adress}>Sv.Roka 80</p>
            <p css={contactInfoStyles.adress}>HR-31000 Osijek</p>
          </motion.div>
        )}
      </InView>
      <InView threshold={0.4} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.p
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.7 }}
            css={contactInfoStyles.emailAdressWrapper}
          >
            E-mail:{' '}
            <a
              css={contactInfoStyles.emailAdress}
              href="mailto:udruga.slap@gmail.com"
            >
              udruga.slap@gmail.com
            </a>
          </motion.p>
        )}
      </InView>
      <InView threshold={0.4} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.p
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.75 }}
            css={contactInfoStyles.phoneNumberWrapper}
          >
            Tel:{' '}
            <a css={contactInfoStyles.phoneNumber} href="tel:+385958850314">
              +385 95 885 0314
            </a>
          </motion.p>
        )}
      </InView>
    </div>
  );
};
