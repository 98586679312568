import { css } from '@emotion/react';
import { breakpoints } from 'style';

const root = css`
  margin-bottom: calc(var(--unit) * 22);
  padding-top: calc(var(--unit) * 26);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 40);
  }
`;

export const contactPageStyles = {
  root,
};
