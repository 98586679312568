import { css } from '@emotion/react';
import { breakpoints, colors, fontFamily } from 'style';

const root = css`
  grid-column: 2 / span 4;
`;

const subtitle = css`
  font-family: ${fontFamily.primary};
  font-weight: 400;
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  margin-bottom: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xLarge);
    line-height: var(--line-height-xxLarge);
    margin-bottom: calc(var(--unit) * 14);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxxLarge);
  }
`;

const button = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--unit) * 45);
  height: calc(var(--unit) * 10);

  border: none;
  border-radius: var(--unit);
  cursor: pointer;

  transition: all 0.2s ease-in-out;
`;

const submitButton = css`
  ${button}
  margin: var(--spacing-xlrg) 0 calc(var(--unit) * 22);

  background-color: ${colors.primary};

  &:hover {
    background-color: ${colors.secondary};
  }

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 9);
    margin-top: var(--spacing-lrg);
  }
`;

const buttonText = css`
  font-family: ${fontFamily.secondary};
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-base);
  text-align: center;

  color: ${colors.white};
`;

const fieldWrapper = css`
  position: relative;
`;

const errorMessage = css`
  position: absolute;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  color: ${colors.error};

  bottom: calc(var(--unit) * 4);
`;

const checkboxMessage = css`
  position: absolute;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  color: ${colors.error};

  bottom: calc(0px - var(--unit) * 12);

  @media ${breakpoints.medium} {
    bottom: calc(0px - var(--unit) * 13);
    margin-bottom: calc(var(--unit) * 2);
  }

  @media ${breakpoints.large} {
    bottom: calc(0px - var(--unit) * 9);
    margin-bottom: 0;
  }
`;

const errorMessageHidden = css`
  display: none;
`;

const toastContainer = css`
  .Toastify__progress-bar--success {
    background: ${colors.primary};
  }

  .Toastify__toast-icon svg {
    fill: ${colors.primary};
  }
`;

export const contactFormStyles = {
  root,
  subtitle,
  button,
  submitButton,
  buttonText,
  errorMessage,
  fieldWrapper,
  checkboxMessage,
  errorMessageHidden,
  toastContainer,
};
